body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.ant-tabs-content {
  height: 100%;
}